




















































import Vue from 'vue';
import AuthenticationAttempt from '@/components/auth/Attempt.vue';

export default Vue.extend({
  components: {
    AuthenticationAttempt,
    PrivacyV3: () => import(/* webpackChunkName: "l-dse-v3" */ '@/components/legal/dse/V3.vue'),
  },
  methods: {
    async handle(attempt: any) {
      const res = await this.$store.dispatch('oauth2/password', {
        username: attempt.id,
        password: attempt.secret,
      });

      if (!res) return;

      const { redirect } = this.$route.query;
      console.debug('[Login] Redirect to:', redirect);

      if (!redirect || redirect === '/') this.$router.push({ name: 'home' });
      else this.$router.push(redirect.toString());
    },
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
